<template>
  <!-- 占位元素,只显示搜索框时使用 -->
  <div
    class="pageHeaddianzi"
    v-if="!onlyTheSearchBoxIsDisplayed && showHead"
  ></div>
  <div
    class="pageHead"
    v-if="showHead"
    :class="{ onlyTheSearchBoxIsDisplayed: onlyTheSearchBoxIsDisplayed }"
  >
    <!-- 公司logo -->
    <div class="logoBox" v-if="!onlyTheSearchBoxIsDisplayed">
      <img
        class="logoImg"
        @click="$fun.routerToPage('/')"
        :src="require('@/assets/icon/app-logo.png')"
        alt=""
      />
    </div>
    <!-- 搜索输入框 -->
    <div class="search">
      <div class="sourceOfGoods">
        <van-popover v-model:show="showPopover" class="jainpan">
          <ul class="listBox">
            <li
              v-for="(item, index) in itemlist"
              @click="appClick(item, index)"
              :key="index"
              :class="{ active: item.value == inputText }"
            >
              <span class="list-item-text">{{ item.key }}</span>
            </li>
          </ul>
          <template #reference>
            <div class="search-module">
              <span>{{ find_source_name(inputText) }}</span>
              <van-icon name="arrow-down" />
            </div>
          </template>
        </van-popover>
      </div>
      <div class="vline"></div>
      <div class="textsearch">
        <input
          type="text"
          :class="{
            canclear: onlyTheSearchBoxIsDisplayed,
            anCss: equipmentType != 'IOS',
          }"
          v-model="$store.state.searchInfo"
          :placeholder="$fanyi('日语·中文关键词或者1688、淘宝的商品及店铺URL')"
          @keydown.enter="search"
          @focus="setshowSearchBtn(true)"
          @blur="setshowSearchBtn(false)"
        />
      </div>
      <!-- <button class="deleteBtn" v-if="showSearchBtn" @click="clearSearchStr">
        <img :src="require('@/assets/icon/shanchuhui.png')" alt="" />       
      </button> -->
      <div class="imgsearchBox">
        <img
          style="color: blue"
          :src="require('@/assets/icon/imgsearch2.png')"
          alt=""
          @click="clickUpload"
        />
      </div>

      <div class="keywordSearchBox">
        <img
          :src="require('@/assets/icon/keysearchwhite.png')"
          alt=""
          @click="search"
        />
      </div>

      <input
        style="display: none"
        type="file"
        id="myFileInput"
        @change="uploadFile()"
      />
    </div>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import { Toast } from "vant";

export default {
  props: {
    // 是否只显示搜索框,在这个组件只需要显示搜索框时使用
    onlyTheSearchBoxIsDisplayed: {
      default: false,
    },
  },
  data() {
    return {
      showSearchBtn: false, //是否显示确认搜索按钮
      value: "",
      itemlist: [
        { key: "1688", value: "1688" },
        { key: "タオバオ＆tmall", value: "taobao" },
        { key: this.$fanyi("日本热卖"), value: "jpOpp" },
        { key: this.$fanyi("韩国热卖"), value: "krOpp" },
      ],
      showText: this.$route.query.goodsForm || "1688",
      inputText: this.$route.query.goodsForm || "1688",
      showPopover: false,
      showName: "",
      equipmentType: this.$fun.judgeClient(),
    };
  },
  created() {
    // 防止搜索栏分类在跳转页面时因重新加载而清空
    this.value = this.$store.state.goodsListActiveId;
  },
  components: { Loading },
  computed: {
    //兼容朱工显示的参数
    showHead() {
      return !this.$route.query.nohead;
    },
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    options() {
      return this.$store.state.goodsList;
    },
    token() {
      return !!this.$store.state.userInfo;
    },
    showClassificationOfGoodsVue() {
      if (["homepage", "CommoditySearch"].indexOf(this.$route.name) != -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.changeHeight();
  },

  methods: {
    find_source_name(v) {
      let redata = this.itemlist.find((ili) => ili.value == v).key;
      console.log(redata.length);
      return redata || v;
    },
    appClick(data, index) {
      this.inputText = data.value;
      this.showText = data.key;
      this.showPopover = false;
    },
    clickUpload() {
      if (!this.$store.state.userInfo) {
        this.$store.commit(
          "changeshowLoginDia",
          !this.$store.state.showLoginDia
        );
        return;
      }
      document.querySelector("#myFileInput").click();
    },
    clearSearchStr() {
      this.$store.commit("getsearchInfo", "");
    },
    // getUser() {
    //   this.$api.EuropegetUserInfo().then((res) => {
    //     this.$store.commit("userData", res.data);
    //   });
    // },
    setshowSearchBtn(status) {
      if (!status) {
        setTimeout(() => {
          this.showSearchBtn = status;
        }, 100);
      } else {
        this.showSearchBtn = status;
      }
    },

    changeHeight() {
      let store = this.$store;
      // window.onscroll = function () {
      //   // if (router.history.current.fullPath == "/") {
      //   let scrollTop =
      //     document.documentElement.scrollTop || document.body.scrollTop;
      // };
    },
    // 返回首页
    backHome() {
      // 假如当前在首页就重新刷新页面
      if (this.$route.name == "homepage") {
        return this.$router.go(0);
      }
      this.$fun.routerToPage("/");
      this.$store.commit("getsearchInfo", "");
    },
    // 跳转搜索页
    search() {
      // 判断是否是链接,如果是链接就跳转到相应页面
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688/);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let regrakumartform = RegExp(/fromPlatform/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg2 = /id=(\d+)/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg5 = /tk=([^&\s]+)/; //taobao手机链接
      // 判断是哪个网站的商品链接
      if (
        regrakumart.test(this.$store.state.searchInfo) &&
        regrakumartform.test(this.$store.state.searchInfo)
      ) {
        // return false;
        id = this.$store.state.searchInfo.match(reg3)[1];
        return this.$fun.toCommodityDetails(id, "1688", false, "link");
      } else if (regtaobao.test(this.$store.state.searchInfo)) {
        // console.log(this.$store.state.searchInfo.match(reg2));
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "taobao", false, "link");
        return false;
      } else if (reg1688.test(this.$store.state.searchInfo)) {
        if (this.$store.state.searchInfo.match(reg1) == null) {
          id = this.$store.state.searchInfo.match(reg4)[1];
        } else {
          id = this.$store.state.searchInfo.match(reg1)[1];
        }
        this.$fun.toCommodityDetails(id, "1688", false, "link");
        return false;
      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "1688");
        return false;
      }
      if (
        this.$store.state.searchInfo.substr(0, 4) == "http" ||
        this.$store.state.searchInfo.indexOf("m.tb.cn") != -1
      ) {
        this.$toast.loading({
          duration: 0,
          message: this.$fanyi("加载中..."),
          forbidClick: true,
          loadingType: "spinner",
        });
        this.$api
          .searchFengGoods({
            keywords: this.$store.state.searchInfo,
            shop_type: 1688,
            page: 1,
            pageSize: 1,
          })
          .then((res) => {
            this.$toast.clear();
            if (res.code != 0) return this.$toast.fail(this.$fanyi(res.msg));
            if (res.data.data_type == "goodsParticulars") {
              this.$fun.toCommodityDetails(
                res.data.result.goodsId,
                res.data.result.fromPlatform,
                false,
                "link"
              );
            } else {
              this.$fun.routerToPage(
                "/CommoditySearch?keyword=" +
                  this.$store.state.searchInfo +
                  "&goodsForm=" +
                  this.inputText
              );
            }
          });
        return;
      } else {
        // 如果是商品搜索页面就跳转
        if (this.$route.name == "CommoditySearch") {
          // return console.log(this.inputText);
          this.$fun.routerToPage(
            "/CommoditySearch?keyword=" +
              this.$store.state.searchInfo +
              "&goodsForm=" +
              this.inputText
          );
          return;
        }
        // 如果关键词为空就不跳转
        if (!this.$store.state.searchInfo) {
          this.$message.warning(this.$fanyi("请输入关键词搜索"));
          return false;
        }
        // 跳转
        this.$fun.routerToPage(
          "/CommoditySearch?keyword=" +
            this.$store.state.searchInfo +
            "&goodsForm=" +
            this.inputText
        );
      }
    },
    uploadFile() {
      var input = document.getElementById("myFileInput");
      var file = input.files[0];
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      this.$toast.loading({
        duration: 0,
        message: this.$fanyi("加载中..."),
        forbidClick: true,
        loadingType: "spinner",
      });
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        Toast.clear();
        return isJPG;
      }
      var _this = this;
      // 将file格式文件转为canvas
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // console.log(e);
        // 创建图片元素
        let img = document.createElement("img");
        img.src = e ? e.target.result : "";
        img.onload = () => {
          let { width, height } = img;
          // 计算宽高比 ...
          // 创建canvas画布
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          let yushesize = 507200; //压缩至300kb
          // let yushesize = 132950;
          let bili = 1;
          if (file.size > yushesize) {
            bili = yushesize / file.size;
          }

          width = width * bili;
          height = height * bili;

          canvas.width = width;
          canvas.height = height;
          context?.clearRect(0, 0, width, height);
          context?.drawImage(img, 0, 0, width, height);
          // 转成blob
          canvas.toBlob(
            (blob) => {
              _this.compressionJudgment(blob);
            },
            file.type,
            0.5 //图片的质量
          );
          // 删除创建的图片元素
          img.remove();
        };
      };
    },
    // 图片上传
    compressionJudgment(blob) {
      // 将blob转化为file格式
      let files = new window.File([blob], `qwert.${blob.type.split("/")[1]}`, {
        type: blob.type,
      });
      this.$fun.httpRequest(files, (res) => {
        this.tipagedata = res.data;
        // // 获取imageId
        // console.log("上传成功");
      });

      this.blobToBase64(blob).then((reader) => {
        // let base64String = base64.substr(index + 7, base64.length);
        this.getImageId(reader);
        return;
        // eslint-disable-next-line no-unreachable
        var img = new Image();
        img.src = reader;
        img.crossOrigin = "Anonymous"; // 如果图片需要跨域访问，可以设置这个属性
        img.onload = function () {
          // 获取图片id的接口还是上传到公司服务器，所以需要压缩，否则会拖慢速度
          let width = img.width;
          let height = img.height;

          let yushesize = 30720; //压缩至300kb
          // let yushesize = 132950;
          let bili = 1;
          if (files.size > yushesize) {
            bili = yushesize / files.size;
          }

          width = width * bili;
          height = height * bili;

          // 将img转为canvas并压缩，再转回base64
          var canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);
          // eslint-disable-next-line no-unused-vars
          var base64 = canvas.toDataURL("image/png"); // 可以改为image/jpeg，image/webp等其他格式
        };
      });
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    // 图片上传成功事件
    // eslint-disable-next-line no-unused-vars
    successup() {
      this.$fun.statistics_page_sum("图搜数量统计");
      if (this.imageId && this.tipagedata) {
        // return;
        this.$store.state.searchInfo = "";
        // return;
        if (this.$route.name == "commoditysearch") {
          location.href =
            "/CommoditySearch?type=imgSearch&imgUrl=" +
            this.tipagedata +
            "&imageId=" +
            this.imageId +
            "&goodsForm=" +
            this.inputText;
        } else {
          this.$fun.routerToPage(
            "/CommoditySearch?type=imgSearch&imgUrl=" +
              this.tipagedata +
              "&imageId=" +
              this.imageId +
              "&goodsForm=" +
              this.inputText
          );
        }
      }
    },
    getImageId(val) {
      let that = this;
      // 将base64转为img
      var img = new Image();
      img.src = val;
      img.crossOrigin = "Anonymous"; // 如果图片需要跨域访问，可以设置这个属性
      img.onload = function () {
        // 获取图片id的接口还是上传到公司服务器，所以需要压缩，否则会拖慢速度
        let width = img.width;
        let height = img.height;
        // 将img转为canvas并压缩，再转回base64
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var base64 = canvas.toDataURL("image/png"); // 可以改为image/jpeg，image/webp等其他格式
        let index = base64.indexOf("base64,");
        let base64String = base64.substr(index + 7, base64.length);
        let datas = {
          url: "/1/com.alibaba.fenxiao.crossborder/product.image.upload",
          uploadImageParam: JSON.stringify({
            imageBase64: base64String,
          }),
        };
        // 调用接口获取图片id
        that.$api.uploadImagePic(datas).then((res) => {
          if (!res.data || (res.data && res.data.code != 200))
            return that.$message.error(that.$fanyi(res.data.message));
          // 获取图片切片列表
          that.imageId = res.data.result;
          that.$toast.clear();
          that.successup();
        });
      };
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.pageHeaddianzi {
  height: 210px;
}

.pageHead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 210px;
  background-color: white;
  padding-bottom: 17px;
  z-index: 2;

  .logoBox {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .logoImg {
      width: 400px;
      // height: 48px;
      display: block;
    }
  }

  .search {
    margin: 0 auto;
    width: 690px;
    height: 66px;
    background: #ffffff;
    border: 2px solid #b4272b;
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .sourceOfGoods {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .textsearch {
      width: 100%;
      display: flex;
      align-items: center;

      input {
        padding: 0 0 0 10px;
        width: 100%;
        border: none;
        height: 66px;
        font-size: 24px;
        position: relative;

        &::placeholder {
          font-size: 11.5px;
        }
      }

      input.anCss {
        &::placeholder {
          font-size: 11.5px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .deleteBtn {
    }

    .imgsearchBox {
      padding-right: 25px;
      padding-left: 10px;
      display: flex;
      align-items: center;

      img {
        width: 39px;
        height: 31px;
      }
    }

    .keywordSearchBox {
      width: 66px;
      flex: 0 0 66px;
      height: 100%;
      background: #b4272b;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 33px;
        height: 33px;
      }
    }

    .vline {
      width: 1px;
      width: 1px;
      height: 26px;
      background: #999999;
    }

    .search-module {
      margin-bottom: auto;
      height: 62px;
      width: 160px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      span {
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .van-icon-arrow-down {
        font-size: 18px;
      }
    }
  }
}

// 只展示输入框时展示
.pageHead.onlyTheSearchBoxIsDisplayed {
  position: unset;
  height: unset;
  padding: 0;

  .search {
    width: 631px;
    margin: 0;
    margin-left: auto;
    height: 66px;

    input {
      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.upImg {
  display: none;
}
</style>
<style lang="scss">
.jainpan {
  .van-popover__content {
    border-radius: 6px;

    .listBox {
      width: 160px;

      li {
        height: 60px;

        display: flex;
        align-items: center;
        margin: 0 20px;

        &:active {
          background-color: #f5f7fa;
        }

        .list-item-text {
          font-size: 24px;
          font-weight: 600;
        }
      }

      li.active {
        color: #b4272b;
      }
    }
  }
}
</style>
